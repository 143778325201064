import React from "react";

import PropTypes from "prop-types";

InvoiceAmounts.propTypes = {
  amount_subtotal: PropTypes.object,
  amount_total: PropTypes.object,
  amount_tax: PropTypes.object,
  currency: PropTypes.string,
  title: PropTypes.string,
};

export default function InvoiceAmounts({
  amount_subtotal,
  amount_total,
  amount_tax,
  currency,
  title,
}) {
  return (
    <div className="amounts-container">
      <div className="payment-details">{title}:</div>
      <div className="invoice-description">
        {(amount_subtotal.content || amount_subtotal.content == 0) && (
          <div className="item-description-row">
            <div className="payment-message">{amount_subtotal.label}: </div>
            <div className="payment-title p-l-16">
              ${(amount_subtotal.content / 100).toFixed(2)}{" "}
              {currency.toUpperCase()}
            </div>
          </div>
        )}
        {(amount_tax.content || amount_tax.content == 0) && (
          <div className="item-description-row">
            <div className="payment-message">{amount_tax.label}: </div>
            <div className="payment-title p-l-16">
              ${(amount_tax.content / 100).toFixed(2)} {currency.toUpperCase()}
            </div>
          </div>
        )}
        {(amount_total.content || amount_total.content == 0) && (
          <div className="item-description-row">
            <div className="payment-message">{amount_total.label}: </div>
            <div className="payment-title p-l-16">
              ${(amount_total.content / 100).toFixed(2)}{" "}
              {currency.toUpperCase()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
