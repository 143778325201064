import React from "react";
import { Routes, Route } from "react-router-dom";
// import { loadStripe } from "@stripe/stripe-js";

import "./App.css";
// import StripeComponent from "./components/StripeComponent";
import CompleteComponent from "./components/CompleteComponent";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_P_KEY, {
//   locale: "fr",
// });

export default function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route
          path="/pay/:id"
          element={<StripeComponent stripePromise={stripePromise} />}
        /> */}
        <Route path="/complete" element={<CompleteComponent />} />
        {/* <Route path="*" element={<NoMatch />} /> */}
      </Routes>
    </>
  );
}
