import React, { useEffect, useState } from "react";
import InvoiceAmounts from "./complete-invoice/InvoiceAmounts";
import DescriptionRows from "./complete-invoice/DescriptionRows";
import { useTranslation } from "react-i18next";
import Config from "../config/config.json";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import NuLogo from "./NuLogo";

export default function CompleteComponent() {
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const checkout_ns = Config.ns.checkout;
  const common_ns = Config.ns.common;
  const { t, i18n } = useTranslation([checkout_ns, common_ns]);
  const [transactionId, setTransactionState]=useState(null);

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get(
      "session_id"
    );

    if (!sessionId) {
      return;
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/api/checkout-detail?id=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_NUPAY_APP_KEY}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setState(data.data);
        setTransactionState(sessionId);
        i18n.changeLanguage(data.data?.locale ?? Config.default_language);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!state) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    window.location.href = state.success_url;
  };

  let payObject = {
    stripeDescription: {
      session_status: {
        content: t(`session.session_status.${state?.session_status}`, { ns: checkout_ns }),
        label: t("session.payment", { ns: checkout_ns }),
      },
      payment_status: {
        content: t(`session.payment_status.${state?.payment_status}`, { ns: checkout_ns }),
        label: t("session.status", { ns: checkout_ns }),
      },
      client_name: {
        content: state?.client_name,
        label: t("session.origin", { ns: checkout_ns }),
      },
      product_name: {
        content: state?.product_name,
        label: t("session.product_name", { ns: checkout_ns }),
      },
    },
    planDescription: {
      subscription: {
        content: `${state?.plan?.interval_count} ${state?.plan?.interval}`,
        label: t("plan.duration", { ns: checkout_ns }),
      },
      planType: {
        content: state?.plan?.active
          ? t("active", { ns: common_ns })
          : t("inactive", { ns: common_ns }),
        label: t("plan.status", { ns: checkout_ns }),
      },
      current_period_start: {
        content: new Date(
          +state?.plan?.current_period_start * 1000
        ).toLocaleDateString(),
        label: t("plan.begins", { ns: checkout_ns }),
      },
      current_period_end: {
        content: new Date(
          +state?.plan?.current_period_end * 1000
        ).toLocaleDateString(),
        label: t("plan.renews", { ns: checkout_ns }),
      },
    },
  };
  return (
    <div>
      <div style={{marginTop:100}}>
        <form id="payment-form" onSubmit={handleSubmit}>
          <NuLogo types="center" withName={false} size="small"></NuLogo>
          <div >
            {state && <DescriptionRows content={payObject.stripeDescription} />}
          </div>

          {state?.subscription && (
            <div className="subscription-details">
              {state && <DescriptionRows content={payObject.planDescription} />}
            </div>
          )}

          {state && (
            <InvoiceAmounts
            title={
              t("invoice_details.title", { ns: checkout_ns })
            }
              amount_subtotal={{
                content: state?.amount_subtotal,
                label: t("subtotal", { ns: common_ns }),
              }}
              amount_total={{
                content: state?.amount_total,
                label: t("total", { ns: common_ns }),
              }}
              amount_tax={{
                content: state?.amount_tax,
                label: t("tax", { ns: common_ns }),
              }}
              currency={state?.currency}
            />
          )}
          {/* Show any error or success messages */}
          <button disabled={isLoading || !state} id="submit">
            <span id="button-text">
              {isLoading || !state ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                `${t("continue", { ns: common_ns })}`
              )}
            </span>
          </button>
          <div>
      <Typography sx={{width: "70vw", minWidth:"initial", maxWidth:"500px", wordBreak: "break-word", marginTop:4}} fontSize="0.9em">
        <span style={{fontWeight:"bold", color:"black"}}>Transaction_ID: </span><span style={{color:"gray"}}>{transactionId}</span>
      </Typography>
      </div>
      </form>
    </div>
    </div>
  );
}
