import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Config from "../config/config.json";
import i18next from "i18next";

// Language Detector options
const options = {
  // order: ["querystring", "navigator"],
  order: ["querystring"],
  lookupQuerystring: "lng",
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [Config.ns.common, Config.ns.checkout],
    defaultNS: Config.ns.common,
    fallbackLng: Config.default_language,
    debug: process.env.NODE_ENV == 'development',
    detection: options,
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "loaded languageChanged",
      bindI18nStore: "added",
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b"],
    },
    supportedLngs: Object.keys(Config.supported_languages),
    backend: {
      loadPath: "/locales/{{ns}}/{{lng}}.json",
    },
  });

export default i18next;
