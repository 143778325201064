import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';

DescriptionRows.propTypes = {
  content: PropTypes.object,
};

export default function DescriptionRows({content}) {
  let columnContent = Object.keys(content);
  return (
    <>
    <Grid container spacing={2}>
      {columnContent.map((item, index) => {
        if(typeof content[item].content !== "undefined")
        {
        return(
            <Grid item xs={6} md={3} key={index}>
              <div className="payment-message">{content[item].label}: </div>
              <div className="payment-title p-l-16">{content[item].content}</div>
            </Grid>
        ) 
        }
      }
      )}
      </Grid>
    </>
  );
}
