import React from "react";
import PropTypes from 'prop-types';

import LogoImageWithName from "../images/nuwelcomLogoWithName.png"
import LogoImageWithoutName from "../images/nuwelcomLogoWithoutName.png"

NuLogo.propTypes={
  types:PropTypes.string.isRequired,
  withName:PropTypes.bool.isRequired,
  size:PropTypes.string.isRequired,
}

export default function NuLogo(props)
{
  let displayOfLogo="flex";
  let justifyContentOfLogo="center";
  let widthOfLogo="100%";
  let logoWithName=LogoImageWithName;
  let logoSize="200";

  //the position of the logo
  switch(props.types)
  {
    case "left":
      justifyContentOfLogo="left";
      widthOfLogo="100vw"
      break;
    case "center":
      break;
    default:
      break;
  }

  //if display the name on logo
  if(!props.withName){
    logoWithName=LogoImageWithoutName;  
  }

  //the size of the logo image
  switch(props.size)
  {
    case "small":
      logoSize="15vw";
      
      break;
    case "medium":
      logoSize="17vw";
      break;
    case "large":
      logoSize="20vw";
      break;
    default:
      break;
  }

    return (
        
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: "column",
        color: "rgb(128, 36, 153)",
        fontFamily: 'Montserrat',
    }}>
        <div style={{
            marginBottom: "2rem",
            textAlign: "center",
            textTransform: "uppercase",
        }}>
            <div style={{
        display:displayOfLogo,
          justifyContent: justifyContentOfLogo, 
          width: widthOfLogo}}>
            <img src={logoWithName} style={{width:logoSize, maxWidth:"120px", minWidth:"90px",}} alt="Nuwelcome Logo of the image"/>
        </div>
            <div style={{
                fontSize: "2.6rem",
                fontWeight: "bold",
            }}>
                Nuwelcom
            <span style={{
                fontSize: "0.5rem",
            }}>
                TM
            </span>
            </div>
        </div>
    </div>
        
    );
}